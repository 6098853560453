<template>
  <!-- 账户安全 -->
  <div class="contentBox">
    <section>
      <div class="formBox" v-if="editSuccessBox">
        <div class="successBox">
          <img :src="editSuccess" alt="" />
          <p>
            手机号修改成功！请牢记，
            {{ successSeconds }}
            s后将返回登录页........
          </p>
          <el-button type="primary" class="radius-25" @click="goLogin">
            立即返回
          </el-button>
        </div>
      </div>
      <el-tabs v-model="activeName" v-if="forgetBox">
        <el-tab-pane label="修改手机" name="first">
          <div class="tabBox">
            <div class="tabTitle">
              更换手机号码
            </div>
            <el-form class="formBox" ref="findInfo" :model="findInfo">
              <el-form-item>
                <el-input
                  placeholder="请输入原手机号"
                  v-model="findInfo.u_phone"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-mobile-phone"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input placeholder="请输入新手机号" v-model="findInfo.phone">
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-mobile-phone"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <DragBar></DragBar>
              </el-form-item>
              <el-form-item>
                <el-input placeholder="请输入验证码" v-model="findInfo.code">
                  <el-button
                    slot="append"
                    type="primary"
                    :disabled="codeBtn"
                    v-prevent-repeat-click
                    @click="gainFindCode"
                    v-if="smsTime > 59"
                  >
                    获取验证码
                  </el-button>
                  <template slot="append" v-if="smsTime < 60">
                    {{ smsTime }}秒后可重发
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item class="text-center">
                <el-button
                  type="primary"
                  class="radius-25"
                  style="width: 280px"
                  v-prevent-repeat-click
                  @click="resetPhone"
                >
                  确定
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="设置密码" name="second">
          <div class="tabBox">
            <div class="tabTitle">
              修改登录密码
            </div>
            <el-form class="formBox" :model="editInfo">
              <el-form-item>
                <el-input
                  placeholder="请输入原密码"
                  type="password"
                  v-model="editInfo.old_password"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  placeholder="请输入新密码"
                  type="password"
                  v-model="editInfo.new_password"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  placeholder="请确认密码"
                  type="password"
                  v-model="editInfo.new_again_password"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
              <el-form-item class="text-center">
                <el-button
                  type="primary"
                  class="radius-25"
                  style="width: 280px"
                  v-prevent-repeat-click
                  @click="resetPhone"
                >
                  确定
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="微信绑定" name="three">
          <div class="tabBox" style="position:relative">
            <div class="tabTitle">
              微信绑定
            </div>
            <div id="codeBox" class="text-center"></div>
            <div class="bandMark" v-show="wxData">
              <img :src="bandMark" alt="" />
            </div>
            <div class="formBox text-center">
              <p style="color: #666;" v-if="!wxData">
                请使用微信扫描二维码登录“建搜搜”
              </p>
              <p style="color: #666;" v-if="wxData">
                已绑定微信：微信昵称
                <span style="color:#333"> {{ userInfo.u_nickname }}</span>
              </p>
              <p style="color: #666;">手机号码 {{ userInfo.u_phone }}</p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>
<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
import { mapState } from "vuex";
import DragBar from "@/components/dragBar/dragbar.vue";
export default {
  data() {
    return {
      editSuccess: require("@/assets/img/success.png"),
      bandMark: require("@/assets/img/bandMark.png"),
      activeName: "first",
      findInfo: { u_phone: "", phone: "", code: "" },
      editInfo: {},
      smsTime: 60,
      successSeconds: 5,
      forgetBox: true,
      editSuccessBox: false,
      wxData: false,
    };
  },
  components: {
    DragBar,
  },
  computed: {
    ...mapState(["dragState", "userInfo", "wxName"]),
    codeBtn() {
      return !this.dragState;
    },
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    // 修改手机
    gainFindCode() {
      if (
        this.findInfo.u_phone === this.findInfo.phone &&
        this.findInfo.phone != ""
      ) {
        this.$message.warning("修改的手机号与原手机号一致");
        return;
      }
      if (this.findInfo.phone == "") {
        this.$message.warning("请检查要修改的手机号");
        return;
      }
      let tel = "+86" + this.findInfo.phone;
      this.$http.post("index/login/codesss", { phone: tel }).then((res) => {
        if (res.data.resultCode === 1) {
          // 验证码发送定时器
          let _this = this;
          let timer = setInterval(function() {
            _this.smsTime--;
            if (_this.smsTime === 0) {
              clearInterval(timer);
              _this.smsTime = 60;
            }
          }, 1000);
          this.$message.success("验证码已发送，请您注意查收！");
        }
      });
    },
    resetPhone() {
      if (this.findInfo.u_phone == "") {
        this.$message.warning("请输入原手机号！");
        return;
      }
      if (this.findInfo.phone == "") {
        this.$message.warning("请输入新手机号！");
        return;
      }
      if (this.findInfo.code == "") {
        this.$message.warning("请输入验证码！");
        return;
      }
      if (this.findInfo.u_phone === this.findInfo.phone) {
        this.$message.warning("修改的手机号与原手机号一致");
        return;
      }
      this.$http.post("talent/user/editphone", this.findInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.forgetBox = false;
          this.editSuccessBox = true;
          let _this = this;
          let timer = setInterval(function() {
            _this.successSeconds--;
            if (_this.successSeconds === 1) {
              _this.goLogin();
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    },
    // 修改密码
    editPost() {
      if (this.editInfo.old_password == "") {
        this.$message.warning("请输入原密码！");
        return;
      }
      if (this.editInfo.new_password == "") {
        this.$message.warning("请输入新密码！");
        return;
      }
      if (this.editInfo.new_again_password == "") {
        this.$message.warning("请确认新密码！");
        return;
      }
      if (this.editInfo.new_password != this.editInfo.new_again_password) {
        this.$message.warning("输入的新密码不一致！");
        return;
      }
      this.$http.post("talent/user/edit", this.editInfo).then((res) => {
        if (res.data.resultCode === 5) {
          this.editBox = false;
          this.editSuccessBox = true;
          let _this = this;
          let timer = setInterval(function() {
            _this.successSeconds--;
            if (_this.successSeconds === 1) {
              _this.goLogin();
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    },
    // 跳转登录
    goLogin() {
      localStorage.removeItem("Authorization");
      this.$router.push({ path: "/Login" });
    },
    // 微信
    setWxerwma() {
      sessionStorage.setItem("loginer", this.userInfo.u_identity);
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      const wxElement = document.body.appendChild(s);
      wxElement.onload = function() {
        var obj = new WxLogin({
          id: "codeBox", // 需要显示的容器id
          appid: "wxa8ea41b6aba67301", // 公众号appid
          scope: "snsapi_login", // 网页默认即可
          redirect_uri: "http%3A%2F%2Fwww.51jiansousou.com", // 授权成功后回调的url  51jiansousou.com   zhaomy-frp.4kb.cn
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: "", // 提供"black"、"white"可选。二维码的样式
          href: "", // 外部css文件url，需要https
        });
      };
    },
    findWxData() {
      if (this.userInfo.u_nickname || this.wxName) {
        this.wxData = true;
      } else {
        this.wxData = false;
      }
    },
  },
  mounted() {
    this.setWxerwma();
    this.findWxData();
  },
};
</script>
<style scoped>
.tabBox {
  width: 800px;
  margin: 0 auto;
}
.el-tabs__item {
  font-weight: bold;
}
.tabTitle {
  font-size: 28px;
  color: #2573f1;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}
.tabTitle::before {
  content: "";
  display: inline-block;
  width: 73px;
  height: 3px;
  background-image: url("../../../assets/img/lineLeft.png");
  margin-right: 24px;
  position: relative;
  top: -7px;
}
.tabTitle::after {
  content: "";
  display: inline-block;
  width: 73px;
  height: 3px;
  background-image: url("../../../assets/img/lineRight.png");
  margin-left: 24px;
  position: relative;
  top: -7px;
}
.formBox {
  width: 500px;
  margin: 0 auto;
}
.successBox {
  text-align: center;
}
.successBox p {
  color: #2573f1;
}
.successBox button {
  margin: 24px;
}
.bandMark {
  width: 282px;
  height: 282px;
  text-align: center;
  position: absolute;
  top: 114px;
  left: 260px;
  background-color: rgba(0, 0, 0, 0.2);
}
.bandMark img {
  width: 95%;
  margin-top: 35px;
}
</style>
